<template>
  <div>
    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex justify-center align-center flex-column"
        >
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#E06919"
              title="Ser criança e adolescente na roça e na cidade"
              titleColor="#1B211B"
            >
              <template v-slot:conteudo>
                <p>
                  Como primeiro tema a ser ressaltado a partir dos relatos das
                  tomadas de termo, percebe-se a alteração dos modos de vida das
                  crianças e adolescentes atingidas. É recorrente, nas tomadas
                  de termo realizadas, o destaque à relação das crianças e
                  adolescentes com o território. Assim como na conversa com a
                  menina de Camargos, relatada na
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_crianca_adolescente' }"
                    >apresentação</router-link
                  >, vemos repetidas vezes as crianças e adolescentes afirmarem
                  que circulavam bem mais na zona rural, frisando um passado
                  anterior ao rompimento em que eram “livres” ou “soltos”, pois
                  podiam sair sozinhos à noite, pedalar e brincar com os
                  vizinhos sem preocupações como segurança.
                </p>
              </template>
            </Card>
          </div>
          <div
            class="d-flex justify-center align-center border__image3 mt-10"
            style="z-index: 10;"
          >
            <p style="z-index: 15;" class="ma-0 white--text">
              [...] a comunidade era bem pequena e, pra mim, era como se todos
              fossem uma família só. Eu costumava pedir bênção para todos os
              moradores da comunidade e chamava todos de tio. Era bem agradável
              a convivência lá. Eu participava dos jogos de truco e dos
              encontros na cachoeira nos finais de semana. Eu acho que viver na
              comunidade era mais seguro… Não sei se as crises de pânico que
              tenho tido têm a ver com essa perda de convivência com as pessoas
              da comunidade… O que eu mais sinto falta de viver na comunidade é
              da minha avó, porque a gente sempre viveu muito próximas. Sexo
              feminino, 17 anos.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_crianca_adolescente_06.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Tainara Torres. Fonte: Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Eu tinha uma boa relação com todos da comunidade, inclusive com os
            moradores mais velhos. Eu estudava na parte da tarde, em Monsenhor
            Horta. Depois do rompimento, ficou muito difícil manter esses laços
            de amizade, porque o contato não é o mesmo. Esse distanciamento com
            essas pessoas acabou tirando a liberdade que existia entre a gente.
            A amizade que tinha acabou... Sexo masculino, 15 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Gostava muito da vida que tinha em Ponte do Gama, pois lá podia
            viver solto na comunidade. Sexo masculino, 7 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Sinto falta da liberdade, de poder fazer tudo o que podia. Saía mais
            com meus primos do que hoje em dia; o jeito de brincar com meus
            primos em Mariana é diferente de quando a gente estava em Paracatu
            de Cima, pela falta de espaço e de liberdade. Sexo masculino, 10
            anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [...] sinto um pouco de falta da minha avó, que morava do lado de
            casa e agora está morando mais afastada, com meu tio. No começo,
            como meus pais retornaram para morar em Pedras, fiquei só eu e meu
            irmão na casa nova, foi difícil essa adaptação de morar sozinhos.
            Quando morava lá em Pedras, eu trabalhava às vezes para os outros,
            na capina, e tinha uma renda própria. Em Mariana, não tenho essas
            oportunidades, e tenho que pedir pros meus pais sempre que preciso
            de algo. Isso tira um pouco da minha autonomia, me incomoda um
            pouco. Sexo masculino, 14 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col cols="12" md="6">
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                A relação com a natureza, que antes fazia parte da rotina, cedeu
                lugar aos novos modos de vida marcados pelo isolamento. As
                brincadeiras, os momentos de descanso e as relações com a
                vizinhança e com os parentes precisaram se adaptar ao modo de
                vida da cidade, distante da rotina antes do rompimento da
                barragem nas comunidades rurais atingidas.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_crianca_adolescente_07.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Crianças de brincam limitadas pela parede da escola. Foto: Lucas de
            Godoy/Jornal A Sirene, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Sinto que o ambiente é muito diferente em Mariana, por ser mais
            poluído. Penso no novo Paracatu [reassentamento comunitário], vou
            querer ir para Mariana só às vezes depois que me mudar. Sexo
            feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Sinto falta do quintal, onde brincava com os animais e subindo nas
            árvores [...]. Não tinha barulho, só os sons do vento nas árvores e
            pássaros, diferente de Mariana, que tem muito barulho. Lá tinha
            muitas árvores, era muito bonito. A gente podia comer fruta direto
            do pé, que sabia que era saudável, mas agora tem que comprar, e não
            sabe se é tão natural, pode ter agrotóxico. Sexo feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Eu costumava ir no rio e nas cachoeiras da região pra nadar, pra
            pescar, pra aproveitar o tempo. Eram lugares agradáveis de ir. Hoje
            em dia, vejo a marca de lama nas árvores, é triste. Sexo masculino,
            14 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Era a comunidade praticamente toda, os filhos das pessoas mais
            velhas que iam pra lá. Aí todo mundo descia pra cachoeira. Ou então,
            quando não era na cachoeira, era na capela. Aí a gente aproveitava a
            grama, fazia um piquenique, jogava bola, queimada, futebol. Sexo
            feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_crianca_adolescente_08.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Criança se diverte próxima a um chiqueiro em Paracatu de Baixo.
            Foto: Tainara Torres / Jornal A Sirene, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center"
          style="z-index: 10;"
        >
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Os saberes e modos de fazer foram redefinidos, já que agora se
                trata da vivência em um lugar em que não há a proximidade com o
                rio, a mata, a terra cultivável, a existência de um ar puro, bem
                como aspectos do cotidiano como a comida feita no
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudo_soberania_beira_fogao' }"
                  >fogão a lenha</router-link
                >, a prática do extrativismo, da pesca e outras atividades. A
                comunidade que existia antes, não há mais. Houve a destruição e
                o rompimento de relações comunitárias, laços familiares, de
                amizade e vizinhança que antes eram bem consolidados.
              </p>
              <p>
                Ademais, as restrições impostas reverberam em situações de
                isolamento nas quais, muitas vezes, o tempo livre passa a ser
                predominantemente destinado a atividades de pouca mobilidade e
                interação social mediatizada, como televisão, celular,
                computador e/ou videogame, alterando drasticamente o processo de
                socialização dessas crianças e adolescentes.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Na comunidade, aprendi com a minha mãe a mexer na terra, a plantar,
            aprendi a forma de cultivo, os cuidados necessários para o
            crescimento das plantas. Gostava dos alimentos que eram preparados
            no fogão a lenha. Sinto falta do sabor. Toda vez que ia para o
            Bento, ia no Bar da Sandra comer coxinha. Sexo masculino, 10 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Pescava… (riso) brincava de pescar, ajudava nas tarefas de casa,
            ajudava colher café, brincava de comidinha, andava de cavalo (riso),
            brincava de escorregar do barranco (riso). Era muito feliz! Agora
            não tem gramado. Meu irmão não vai poder fazer as mesmas coisas.
            Sexo feminino, 14 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [...] Quando estava com minha avó, a ajudava com algumas tarefas,
            como juntar lenha e cozinhar no fogão a lenha. Minha avó fazia chup
            chup, e eu gostava de comer alguns enquanto estava lá. Também
            gostava de comer jabuticabas no pé. Depois, quando ia para casa,
            fazia os deveres e brincava com meus brinquedos e os cachorros no
            quintal. Também ia junto com meus pais para buscar esterco para
            adubar as plantas. Sexo feminino, 9 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Minha vida não está do jeito que eu imaginava, está pior. Eu fico o
            dia inteiro dentro de casa, isso é muito ruim, não tem nada para
            fazer, todos os dias é a mesma coisa, toda a semana. [...] Agora
            tenho sentimento de perda, a vida mudou toda [...]. Agora não faço
            nada. Sexo masculino, 13 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [Atualmente fico] 24 horas dentro de casa. Só saio pra escola. [...]
            Fico mais no celular e, quando eu não estou no celular, o que tem
            pra fazer é dormir. [Antes do rompimento] Voltava até 6 horas da
            manhã, porque a festa era em frente da minha casa, bastava
            atravessar a rua pra chegar. Em Mariana, depende de uma companhia
            pra ir junto, e às 22 horas preciso estar em casa. Sexo feminino, 12
            anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Em Paracatu de Cima, usava o meu tempo livre jogando bola, pescando,
            andando no mato e visitando os meus vizinhos. Em Mariana, uso o
            tempo livre jogando videogame ou mexendo no celular. Com o
            rompimento da barragem, a minha rotina de vida mudou. Eu preferia
            fazer as coisas que fazia no meu tempo livre em Paracatu de Cima.
            Hoje em dia, faço sempre as mesmas coisas todo dia, por isso lá era
            muito melhor. Sexo masculino, 17 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Na roça não me sentia estressada como na cidade. Na cidade fico
            preocupada com ter e usar celular, enquanto na roça não me
            preocupava com isso. Na roça tinha muita coisa para fazer, uma roça
            para cuidar, brincadeiras, e hoje fico mais em casa, assistindo
            novela pelo celular e ajudando. Sexo feminino, 9 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Agora estudo à tarde e, de manhã, quando levanto, ajudo minha mãe
            com as tarefas de casa e a cuidar dos cachorros, e quando não tem
            tarefas para fazer, assisto televisão ou mexo no celular da minha
            mãe. Sexo feminino, 9 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Após o rompimento, vim morar numa casa sem espaço livre, fechada.
            Longe dos parentes e amigos de infância. Como sou introspectivo, sem
            amigos, me tornei anti-social, fico mais em casa, não tenho
            interesse por rede social, jogos eletrônicos ou TV. Sexo masculino,
            15 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_crianca_adolescente_09.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Francielle Oliveira/Cáritas MG, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center"
          style="z-index: 10;"
        >
          <Card borderColor="#E06919">
            <template v-slot:conteudo>
              <p>
                Além do livre acesso que tinham aos espaços das comunidades
                rurais, há ainda a redução significativa das relações sociais.
                Durante as tomadas de termo, quando questionadas a respeito do
                momento anterior ao desastre–criminoso, as crianças e
                adolescentes fazem referência a uma rede de relações e
                sociabilidade bastante ampla. Como exemplo, o “nós” citado pelas
                crianças e adolescentes:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [...] era uma comunidade pequena. Tinha umas 50 casas só, e cada
            casa tinha umas 3, 4 pessoas. Então dava pra ir na casa de todo
            mundo pra visitar, bater papo, essas coisas. [...] lá era tipo todo
            mundo uma família. Sexo feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            [...] tomava benção de várias pessoas mais velhas, cumprimentava
            muitas pessoas nas ruas, jogava bola no campo. Não lembro os nomes,
            mas lembro de todas as pessoas. Sexo masculino, 16 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Agora não vejo mais minha avó com a mesma frequência que antes,
            agora só a encontro em alguns finais de semana. Nos finais de semana
            em Paracatu [de Baixo], passava o dia brincando com meus amigos e
            com a família. Prefiro a vida de Paracatu, pois podia brincar e ver
            minha avó quando quisesse. Agora, em Mariana, não consigo ver a
            família e os amigos com a mesma frequência e sinto falta disso. Sexo
            feminino, 9 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Morando em Mariana, eu não saio muito, porque acho que não tenho
            muita proximidade com as pessoas da cidade. Eu saio mais para ir a
            faculdade, não tenho uma vida social em Mariana, até mesmo porque
            minha mãe se preocupa muito por estarmos numa cidade maior. Sexo
            feminino, 17 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Agora só vejo minha avó em alguns finais de semana, encontro a
            família toda com menos frequência e sinto muita saudade de poder
            estar perto de todos. Sexo feminino, 12 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex flex-row-reverse">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Conversava com os meus vizinhos do quintal, janela e porta da minha
            casa e encontrava com meus amigos todo final da tarde [...]. Hoje eu
            só tenho contato com os meus amigos por telefone, e as pessoas mais
            antigas que eu tinha contato e conversava todos os dias eu não tenho
            mais contato. Sexo masculino, 15 anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row class="d-flex">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center border__image3 mt-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0 white--text">
            Antes do rompimento, minha família ia raramente até o centro de
            Mariana e depois se mudou pra lá. Poucas vezes eu ouvia barulho de
            carro em Ponte do Gama. Aqui em Mariana o barulho é bem mais alto do
            que em Ponte do Gama. Na comunidade não tinha barulho quando eu ia
            dormir, enquanto que aqui na cidade eu vou dormir com muito barulho
            de carro na rua. Em Mariana não tem muita liberdade para sair, e eu
            só vou onde minha mãe deixa e bem pertinho de casa. Em Mariana é
            perigoso sair sozinho, e minha mãe fica preocupada se eu saio. Eu
            acho que um dia ia sair de Ponte do Gama, mas não do jeito como foi.
            Em Ponte do Gama eu brincava de pular corda e jogar bola, e aqui em
            Mariana só tem espaço para andar de skate. Eu acho que a lama levou
            a oportunidade de ter uma infância mais livre. Sexo masculino, 7
            anos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb d-flex justify-center align-center">
      <v-col
        cols="12"
        md="10"
        class="d-flex justify-center align-center"
        style="z-index: 10;"
      >
        <Card borderColor="#E06919">
          <template v-slot:conteudo>
            <p>
              O antes do rompimento, evento crítico na vida dessas crianças e
              adolescentes, é marcado pelas relações comunitárias mais
              ampliadas, vivenciadas de forma bem próxima aos vizinhos e
              familiares, que normalmente se encontram concentrados em uma mesma
              localidade quando viviam na zona rural. Como pode ser visto nos
              trechos de falas destacados anteriormente, na condição atual de
              vida imposta às crianças e adolescentes atingidos na zona urbana
              de Mariana prepondera a sensação de isolamento, distanciamento e
              falta de pertencimento.
            </p>
            <p style="text-align: center;">
              <strong
                >Autoria: Jéssica Pinheiro<sup>1</sup>, Maria Carolina
                Zanetti<sup>2</sup>, Marisa Versiani<sup>3</sup> e Thalita
                Araújo<sup>4</sup>.
              </strong>
            </p>
          </template>
        </Card>
      </v-col>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Especialista em advocacia extrajudicial;
              Pós-graduanda em Direito Público pela Escola Brasileira de
              Direito. Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>2</sup> Graduanda em Direito na Universidade Federal de Ouro
              Preto. Assessora Técnica Nível Médio na ATI Mariana.
            </p>
            <p>
              <sup>3</sup> Bacharela em Direito pela Faculdade de Direito de
              Conselheiro Lafaiete. Assessora Jurídica na ATI Mariana.
            </p>
            <p>
              <sup>4</sup> Mestranda em Direito pela Universidade Federal de
              Ouro Preto. Bacharela em Direito pela mesma Universidade.
              Assessora Jurídica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Crianças e Adolescentes",
          href: "conteudos_tematicos_crianca_adolescente",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__mb {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vp_03.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 2em 2em 0;
}
</style>
